import React from 'react';
import '../App.css';
import './BucketList.css';
import ContactBubble from './ContactBubble';

const BucketListStatus = {
  TODO: 'TODO',
  PLANNED: 'PLANNED',
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  POSTPONED: 'POSTPONED',
};

const BucketListItems = [
  { id: 1, title: 'Live Rent-Free 🚫🏚️💸', description: 'Owning my own existence! F$#K paying it to my Landlord!', status: BucketListStatus.COMPLETED },
  { id: 2, title: 'Buzz My Hair 👨🏻‍🦲', description: 'Always wanted to be Bald :)', status: BucketListStatus.PLANNED },
  { id: 3, title: 'Explore an Abandoned Building 🏚️🔦', description: 'Want to experience some sp00ky 👻 paranormal activity!', status: BucketListStatus.POSTPONED },
  { id: 4, title: 'Surf in the Barrel of a Wave 🌊🏄🏻', description: 'Catch the perfect wave and ride inside the barrel!', status: BucketListStatus.INPROGRESS },
  { id: 5, title: 'Climb a Cool Cliff 🧗🏻', description: 'Conquer a Badass Cliff Face!', status: BucketListStatus.POSTPONED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7376068292722707754' },
  { id: 6, title: 'Snowboard down Mount Rainier 🏂', description: 'Shred the Gnarly Backcountry Slopes of Mount Rainier!', status: BucketListStatus.TODO },
  { id: 7, title: 'See A Grizzly Bear 🐻 Stand Up!', description: 'Witness the Power of a Grizzly Bear Up Close!', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7413097709432884511', instagram_link: 'https://www.instagram.com/reel/C_v88qIROZp', youtube_link: 'https://www.youtube.com/shorts/niy-EQH6hZE' },
  { id: 8, title: 'Go Dog Sledding 🦮🛷', description: 'Mush! Experience the thrill of dog sledding!', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7410553468454309150', instagram_link: 'https://www.instagram.com/reel/C_iAug1RpIi', youtube_link: 'https://www.youtube.com/shorts/AnBYYdLKXA4' },
  { id: 9, title: 'Ride a Horse through a Canyon ⛰️🏇🏼', description: 'Giddy up! Ride a horse through a breathtaking canyon!', status: BucketListStatus.PLANNED },
  { id: 10, title: 'Sandboard Down a Dune 🏂🏜️', description: 'Feel the rush of sandboarding down a massive dune!', status: BucketListStatus.INPROGRESS },
  { id: 11, title: 'Spearfish 𐃆🐠 in the Ocean', description: 'Catch a fish and cook it up for dinner!', status: BucketListStatus.PLANNED },
  { id: 12, title: 'Sky Dive Over the Grand Canyon', description: 'See the grand canyon from way high up!', status: BucketListStatus.TODO },
  { id: 13, title: 'Run a Sub-7 Minute Mile 🏃🏻', description: 'Push my limits and run a mile in under 7 minutes!', status: BucketListStatus.COMPLETED },
  { id: 14, title: 'Relax in a Hot Spring ', description: 'Soak and unwind in a natural hot spring!', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7384992521476230443', instagram_link: 'https://www.instagram.com/reel/C8s4NYYRcgF', youtube_link: 'https://www.youtube.com/shorts/Dz4mcAdEdbU' },
  { id: 15, title: 'Touch my toes', description: 'Achieve the flexibility to touch my toes!', status: BucketListStatus.INPROGRESS },
  { id: 16, title: 'See a Whale Breaching 🐋', description: 'Witness the majestic sight of a whale breaching!', status: BucketListStatus.INPROGRESS, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7401929561304239390' },
  { id: 17, title: 'Drink Glacier Water 🧊🏔️', description: 'Sip the purest water on this heavenly earth!!', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7406107171638889759', instagram_link: 'https://www.instagram.com/reel/C_DbbinRW5g', youtube_link: 'https://www.youtube.com/shorts/NmSb8nD3bGI' },
  { id: 18, title: 'Run a Mushroom Soup Stand', description: 'Serve up some delicious mushroom soup on a mountain!', status: BucketListStatus.TODO },
  { id: 19, title: 'Run a message in a bottle service', description: 'Pass on anonymous messages to strangers!', status: BucketListStatus.INPROGRESS },
  { id: 20, title: 'Be a Beekeeper 🐝🧤', description: 'Experience the buzz and become a beekeeper for a day!', status: BucketListStatus.TODO },
  { id: 21, title: '🌅 on the California Coast', description: 'Experience the best sunset in the world!', status: BucketListStatus.INPROGRESS },
  { id: 22, title: 'Paint a mural', description: 'Express myself by painting a large-scale mural!', status: BucketListStatus.TODO },
  { id: 23, title: 'Film 100 species of animals', description: 'Capture the beauty of 100 different animal species with my camera!', status: BucketListStatus.INPROGRESS, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7386161076032785710' },
  { id: 24, title: 'Have Joji gain some courage', description: 'Help my cat Joji become braver and more adventurous!', status: BucketListStatus.INPROGRESS },
  { id: 25, title: 'Dye my hair', description: 'Switch up my look by dyeing my hair!', status: BucketListStatus.PLANNED },
  { id: 26, title: 'Swim with sharks', description: 'Get over my fear of sharks! 😓', status: BucketListStatus.PLANNED },
  { id: 27, title: 'Stay Overnight at a Lookout', description: '🌲🔥🔭 Live the life of Firewatch', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7424307178724396319', instagram_link: 'https://www.instagram.com/reel/DA9oRElTVOP', youtube_link: 'https://youtube.com/shorts/aZF6whnYuxU?si=29_32aA-b80yeNGF' },
  { id: 28, title: 'Enter a Food Eating Competition', description: 'Gorge Myself to the extreme!', status: BucketListStatus.TODO },
  { id: 29, title: 'Rest with the Ferns on a Forest Floor', description: 'Pretend that I am a plant', status: BucketListStatus.INPROGRESS },
  { id: 30, title: 'See the Northern Lights', description: 'Aurora Borealis', status: BucketListStatus.COMPLETED },
  { id: 31, title: 'Get my First Tattoo', description: 'Ink up! Get a meaningful tattoo!', status: BucketListStatus.POSTPONED },
  { id: 32, title: 'Backpack in Banff', description: 'Do a backpacking trip in Banff National Park!', status: BucketListStatus.COMPLETED, tiktok_link: 'https://www.tiktok.com/@corporate100/video/7394997143213215019', instagram_link: 'https://www.instagram.com/reel/C90PRlYR5Oo', youtube_link: 'https://www.youtube.com/shorts/aeZLH_K6d8w' },
  { id: 33, title: 'Frolick in a Wildflower Field', description: 'Sound of Music', status: BucketListStatus.COMPLETED },
  { id: 34, title: 'Make a piece of pottery', description: 'Get my hands dirty and create a unique pottery piece!', status: BucketListStatus.TODO },
  { id: 35, title: 'Outer Wilds 🪐🔭', description: 'Stargaze with a telescope!', status: BucketListStatus.PLANNED },
  { id: 36, title: 'Read and review 10 books', description: 'Expand my mind by reading and reviewing 10 books!', status: BucketListStatus.INPROGRESS },

  // New
  { id: 37, title: 'Explore a Cave Network', description: 'Try not to get Claustrophobia!', status: BucketListStatus.TODO },
  { id: 38, title: 'Dumpster Diving', description: 'Relive Joji\'s Stray Cat Days!', status: BucketListStatus.PLANNED },
  { id: 39, title: 'Visit The Mystery Shack', description: 'See the town that inspired Gravity Falls!', status: BucketListStatus.INPROGRESS },
  { id: 40, title: 'Go Bull Riding 🐂', description: 'This ain\'t my first rodeo!', status: BucketListStatus.PLANNED },
  { id: 41, title: 'Cliff Jumping', description: 'Do a flip!', status: BucketListStatus.TODO },
  { id: 42, title: 'Meditate under a Waterfall', description: 'Undergo Hashira Training!', status: BucketListStatus.PLANNED },
  { id: 43, title: 'Run Across a Reflection Lake 🪞', description: 'Tanjiro\'s Soul!', status: BucketListStatus.TODO },
  { id: 44, title: 'Learn how to Fire Dance!', description: 'Be like Zuko 🔥!', status: BucketListStatus.TODO },
  { id: 45, title: 'Go on Set of a Movie', description: 'See what its like to be a Hollywood Star!', status: BucketListStatus.TODO },
  { id: 46, title: 'Sing at an Open Mic Night', description: 'Get Over my Stage Fright!', status: BucketListStatus.INPROGRESS },


  // Future
  // { id: 1, title: 'Change My Name', description: 'Form my own identity!', status: BucketListStatus.TODO },
  // { id: 2, title: 'Wingsuit Gliding', description: 'HTTYD!', status: BucketListStatus.TODO },
  // { id: 3, title: 'Motorcycle through the Desert', description: '!', status: BucketListStatus.TODO },
  // { id: 4, title: 'Learn how to Backflip', description: '!', status: BucketListStatus.TODO },
  // { id: 5, title: 'Learn how to do a Gainer on a Snowboard', description: '!', status: BucketListStatus.TODO },
  // { id: 6, title: 'Bench 225', description: '!', status: BucketListStatus.TODO },
  // { id: 7, title: 'Run a Marathon', description: '!', status: BucketListStatus.TODO },
  // { id: 8, title: 'Summit Mt. Denali', description: '3% Death Rate!', status: BucketListStatus.TODO },
  // { id: 9, title: 'Learn how to Lasso Cattle', description: 'Do Some Cowboy Shit!', status: BucketListStatus.TODO },
  // { id: 10, title: 'Caravan around with Friends', description: '!', status: BucketListStatus.TODO },

  // Ending
  // { id: 98, title: '', description: '!', status: BucketListStatus.TODO },
  // { id: 99, title: '', description: '!', status: BucketListStatus.TODO },
  // { id: 100, title: '', description: '!', status: BucketListStatus.TODO },
];

const getBadgeClass = (status) => {
  switch (status) {
    case BucketListStatus.TODO:
      return 'badge-todo';
    case BucketListStatus.PLANNED:
      return 'badge-planned';
    case BucketListStatus.INPROGRESS:
      return 'badge-in-progress';
    case BucketListStatus.COMPLETED:
      return 'badge-completed';
    case BucketListStatus.POSTPONED:
      return 'badge-postponed';
    default:
      return '';
  }
};

const BucketList = () => (
  <div>
    <ContactBubble />
    {BucketListItems.map((exp, index) => (
      <div key={exp.id} className={`experience-item ${exp.status === BucketListStatus.COMPLETED ? 'completed' : exp.status === BucketListStatus.INPROGRESS ? 'inprogress' : exp.status === BucketListStatus.PLANNED ? 'planned' : 'incomplete'}`}>
        <span className={`badge ${getBadgeClass(exp.status)}`}>{exp.status}</span>
        <h3>
          {exp.status === BucketListStatus.COMPLETED && '✅'} {index + 1}. {exp.title}
        </h3>
        <p>{exp.description}</p>
        {
          exp.status === BucketListStatus.COMPLETED && (exp.tiktok_link || exp.instagram_link || exp.youtube_link) ? (
            <div className="watch-on">
              <div className="icon-links">
                <h4>Watch on:</h4>
                {exp.tiktok_link && (
                  <a className="link" href={exp.tiktok_link} target="_blank" rel="noopener noreferrer">
                    <img src="/tiktok_icon.webp" alt="TikTok" />
                  </a>
                )}
                {exp.instagram_link && (
                  <a className="link" href={exp.instagram_link} target="_blank" rel="noopener noreferrer">
                    <img src="/instagram_icon.png" alt="Instagram" />
                  </a>
                )}
                {exp.youtube_link && (
                  <a className="link" href={exp.youtube_link} target="_blank" rel="noopener noreferrer">
                    <img src="/youtube_icon.png" alt="YouTube" />
                  </a>
                )}
              </div>
            </div>
          ) :
            exp.status === BucketListStatus.COMPLETED && !exp.tiktok_link ? (
              <h4>
                Video Coming Soon!
              </h4>
            ) :
              exp.status !== BucketListStatus.COMPLETED && exp.tiktok_link ? (
                <a href={exp.link} target="_blank" rel="noopener noreferrer">
                  Watch What I Have So Far on Tiktok!
                </a>
              ) :
                <a href={`mailto:danny@corporate-100.com?subject=I want to help you with #${index + 1}. ${exp.title}!!`} target="_blank" rel="noopener noreferrer">
                  Want to Help Me Out? Contact Me about this Specific Item!
                </a>}
      </div>
    ))}
  </div>
);

export default BucketList;